<template>
  <div style="margin: 20px">
    <div class="bg-white p-12">
      <div class="flex items-center mb-4">
        <i
          class="el-icon-arrow-left font-bold text-xl cursor-pointer"
          style="color: #999999"
          @click="goBack"
        />
        <p class="text-xl ml-4 font-bold" style="color: #ff8e3b">
          New Registration
        </p>
      </div>
      <el-form
        label-position="top"
        :model="formData"
        :rules="rules"
        ref="registrationForm"
      >
        <el-form-item label="Registration Name:" prop="name">
          <el-input v-model="formData.name"></el-input>
        </el-form-item>
        <el-form-item label="Introduction:" prop="introduction">
          <el-input
            type="textarea"
            rows="3"
            v-model="formData.introduction"
          ></el-input>
        </el-form-item>
        <el-form-item label="Year:" prop="year">
          <el-select v-model="formData.year">
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="Type:">
          <el-input v-model="formData.type" disabled></el-input>
        </el-form-item>
        <el-form-item label="Tags:">
          <el-input-tag v-model="formData.tags"></el-input-tag>
        </el-form-item>
        <el-button type="primary" @click="onSubmit" style="margin-top: 20px"
          >Submit</el-button
        >
      </el-form>
    </div>
  </div>
</template>

<script>
import { updateRegistration } from "../../api/eae";
import { getUserId } from "../../utils/store";
export default {
  name: "registrationEdit",
  data() {
    return {
      formData: {
        name: "",
        introduction: "",
        year: "",
        tags: [],
        type: "",
      },
      rules: {
        name: [
          { required: true, message: "Please input name", trigger: "blur" },
        ],
        introduction: [
          {
            required: true,
            message: "Please input introduction",
            trigger: "blur",
          },
        ],
        year: [
          { required: true, message: "Please select year", trigger: "change" },
        ],
      },
      options: [
        {
          label: "20-21",
          value: "20-21",
        },
        {
          label: "21-22",
          value: "21-22",
        },
        {
          label: "22-23",
          value: "22-23",
        },
        {
          label: "23-24",
          value: "23-24",
        },
        {
          label: "24-25",
          value: "24-25",
        },
      ],
    };
  },
  methods: {
    goBack() {
      this.$router.push("/home/registration");
    },
    onSubmit() {
      this.$refs["registrationForm"].validate(async (valid) => {
        if (valid) {
          const res = await updateRegistration({
            student_id: getUserId()
          });
          console.log("submit!", res);
        } else {
          return false;
        }
      });
    },
  },
};
</script>